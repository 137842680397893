"use client"

import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

const AnimationContext = createContext<{
  animationsDisabled: boolean;
  toggleAnimations: () => void;
} | undefined>(undefined);

const AnimationDisableProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [animationsDisabled, setAnimationsDisabled] = useState(false);

  const toggleAnimations = () => {
    setAnimationsDisabled((prevState) => !prevState);
  };

  // Effect to handle spotlight animation element
  useEffect(() => {
    const spotlightElement = document.getElementById('spotlight-animation');
    if (spotlightElement) {
      if (animationsDisabled) {
        spotlightElement.style.display = 'none';
        // Optionally, you can also remove any animation classes
        spotlightElement.classList.remove('animate-spotlight'); // Replace with your actual animation class
      } else {
        spotlightElement.style.display = 'block';
        // Optionally, restore animation classes
        spotlightElement.classList.add('animate-spotlight'); // Replace with your actual animation class
      }
    }
  }, [animationsDisabled]); // Re-run effect when animationsDisabled changes

  return (
    <AnimationContext.Provider value={{ animationsDisabled, toggleAnimations }}>
      {children}
    </AnimationContext.Provider>
  );
};

export const useAnimationDisableToggle = () => {
  const context = useContext(AnimationContext);
  if (context === undefined) {
    throw new Error('useAnimationDisableToggle must be used within AnimationDisableToggleProvider');
  }
  return context;
};

export default AnimationDisableProvider;