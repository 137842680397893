import { useMemo } from 'react';
import content from '../content.json';
import { useReadingLevel } from '@/components/wrappers/SimpleTextProvider';
import { ContentType } from '@/types/ContentTypes';

const typedContent: ContentType = content;

type NestedContent = string | { default: string; simple: string } | undefined;

const getNestedContent = (obj: unknown, path: string): NestedContent =>
  path.split('.').reduce<NestedContent>((acc, key) => {
    if (acc && typeof acc === 'object' && key in acc) {
      return (acc as Record<string, unknown>)[key] as NestedContent;
    }
    return undefined;
  }, obj as NestedContent);

export const useContent = (
  page: keyof ContentType, 
  element: string
): string => {
  const { isSimple } = useReadingLevel();

  return useMemo(() => {
    const pageContent = typedContent[page];
    const elementContent = getNestedContent(pageContent, element);

    if (
      elementContent &&
      typeof elementContent === 'object' &&
      'default' in elementContent &&
      'simple' in elementContent
    ) {
      return elementContent[isSimple ? 'simple' : 'default'];
    }

    console.warn(`Content not found for ${page}.${element}`);
    return '';
  }, [isSimple, page, element]);
};
