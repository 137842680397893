// src/components/wrappers/SimpleTextProvider.tsx
import React, { createContext, useContext, useState, ReactNode, useCallback } from 'react';

type SimpleTextContextType = {
  isSimple: boolean;
  toggleSimpleText: () => void;
};

const SimpleTextContext = createContext<SimpleTextContextType | undefined>(undefined);

export const SimpleTextProvider = ({ children }: { children: ReactNode }) => {
  const [isSimple, setIsSimple] = useState(false);

  const toggleSimpleText = useCallback(() => {
    console.log('Toggling simple text');
    setIsSimple((prev) => !prev);
  }, []);

  return (
    <SimpleTextContext.Provider value={{ isSimple, toggleSimpleText }}>
      {children}
    </SimpleTextContext.Provider>
  );
};

export const useReadingLevel = () => {
  const context = useContext(SimpleTextContext);
  if (!context) throw new Error('useReadingLevel must be used within SimpleTextProvider');
  return context;
};
